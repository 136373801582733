import React, { useEffect, useState } from 'react';

import { useAbTest } from '@origin-digital/optimizely';
import { TrustPilotWidget } from '@origin-digital/trustpilot-widget';

import { IOffers } from '../../models/content.interfaces';

import {
  APP,
  BANNER,
  OFFERS,
  PRODUCT_QUICK_LINKS,
  QUICK_LINKS,
  QUICK_LINKS_A,
  READY_TO_SWITCH,
  REWARDS_BENEFITS,
} from '../../consts/staticContent';
import { COLOURS } from '../../consts/style';
import { config } from '../../config';
import { App } from '../App';
import { HeroBanner } from '../Banner/HeroBanner';
import { Offers } from '../Offers';
import { ProductQuickLinks } from '../ProductsQuickLinks';
import { QuickLinks } from '../QuickLinks';
import { RewardsBenefits } from '../RewardsBenefits';
import { Section, SectionOuterOnly, SectionSidePadding } from '../Common';
import ReadyToSwitch from '../ReadyToSwitch';
import { HiddenContentCard } from '../Test/HiddenContentCard';
import { updateTitleOnVisibilityChange, getTestContent } from '../../utils/utils';

export const PageContent = () => {
  const [quickLinkContent, setQuickLinkContent] = useState<any | undefined>(undefined);

  updateTitleOnVisibilityChange();

  const activeExperimentQL = useAbTest(config.optimizelyExperiment?.MAR439);

  useEffect(() => {
    setQuickLinkContent(getTestContent(activeExperimentQL, QUICK_LINKS, QUICK_LINKS_A, 'A'));
  }, [activeExperimentQL]);

  return (
    <main>
      <Section paddingTop={false} variant="full_width">
        <HeroBanner {...BANNER} />
        <SectionSidePadding>
          {quickLinkContent && <QuickLinks {...quickLinkContent} />}
        </SectionSidePadding>
      </Section>
      <Section paddingTop={false}>
        <ProductQuickLinks {...PRODUCT_QUICK_LINKS} />
      </Section>
      <Section variant="full_width" paddingTop={false}>
        <Offers {...(OFFERS as IOffers)} />
      </Section>
      <Section paddingTop={false}>
        <RewardsBenefits {...REWARDS_BENEFITS} />
      </Section>
      <SectionOuterOnly bgColor={COLOURS.NEUTRALS.BACKGROUND_LIGHT}>
        <TrustPilotWidget backgroundColor="transparent" />
      </SectionOuterOnly>
      <Section>
        <App {...APP} />
      </Section>
      <Section bgColor={COLOURS.NEUTRALS.BACKGROUND_LIGHT}>
        <ReadyToSwitch {...READY_TO_SWITCH} />
      </Section>
      {/* component for confirming Braze numbers */}
      <HiddenContentCard />
    </main>
  );
};
