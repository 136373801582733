import React, { useEffect, useState } from 'react';

import {
  IContentCardBase,
  IContentCardsResult,
  contentCards,
} from '@origin-digital/event-dispatcher';

import { LOCATIONS } from '../../consts/config';
import { impression } from '@origin-digital/reporting-client';

export const HiddenContentCard = () => {
  const [content, setContent] = useState<IContentCardBase | undefined>(undefined);

  useEffect(() => {
    impression('braze', {
      type: 'component',
      location: LOCATIONS.testAnalytics,
      experienceId: 'PO : Content Card : MAR-192 August audience sum',
      action: 'view',
    });

    contentCards({
      location: LOCATIONS.testAnalytics,
      limit: 1,
    }).then(({ cards }: IContentCardsResult) => {
      const [card] = cards;
      setContent(card);

      // Braze logging
      card?.logImpression?.();
      // Adobe logging
      impression('braze', {
        type: 'component',
        location: LOCATIONS.testAnalytics,
        experienceId: card?.experienceId ?? `PO : Content Card : MAR-192 August no-content`,
        action: 'view',
      });
    });
  }, []);

  if (!content) {
    return null;
  }

  return <div data-id="hidden-content-card" />;
};
