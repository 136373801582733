import React from 'react';

import { ThemeProvider } from 'styled-components';

import { ColourVariant, IOffer, Theme } from 'src/models/content.interfaces';
import { ConditionalWrapper } from '../Common/ConditionalWrapper';
import { getCloudinaryUrl, kebabCase } from '../../utils/utils';
import { Heading } from '../Common/Headings';
import { Link } from '../Common/Link';
import { OFFERS_PREFIX } from '../../consts/tracking';
import { OFFERS_THEME_MAP } from '../../consts/style';
import { Tag } from '../Common/Tag';
import { Text } from '../Common/Text';
import {
  BIWrapper,
  Card,
  Category,
  FooterWrapper,
  HeaderWrapper,
  PaddingWrapper,
} from './Offers.styles';
import { OfferImage } from './OfferImage';

export const Offer = ({
  accessibleLabel,
  category,
  tag,
  description,
  heading,
  image,
  imageAltText = '',
  imageBackground = false,
  imagePadding = false,
  imagePositionBottom = false,
  label,
  url,
  terms,
  theme,
  isCentred,
}: IOffer) => {
  const layoutClassName = isCentred ? 'layout--centred' : '';
  const cleanThemeName = theme && OFFERS_THEME_MAP[theme] ? theme : 'white';
  const selectTheme = OFFERS_THEME_MAP[cleanThemeName];
  const imageSrc = image ? getCloudinaryUrl(image) : '';

  const themeOptions = {
    themeName: cleanThemeName,
    imageBackground,
    imageSrc,
  };

  return (
    <ThemeProvider theme={{ ...selectTheme, ...themeOptions }}>
      <Card data-id={`offer-theme--${cleanThemeName}`} className={layoutClassName}>
        <section>
          <HeaderWrapper>
            <ConditionalWrapper
              condition={imageBackground}
              wrapper={(children) => (
                <BIWrapper data-id="offer-image--background">{children}</BIWrapper>
              )}
            >
              {(category || tag!.text) && (
                <PaddingWrapper>
                  {category ? (
                    <Category>{category}</Category>
                  ) : (
                    <Tag colour={tag!.colour} text={tag!.text} />
                  )}
                </PaddingWrapper>
              )}
            </ConditionalWrapper>
            {image && !imagePositionBottom && !imageBackground && (
              <OfferImage
                dataId="offer-image--top"
                imageSrc={imageSrc}
                imageAltText={imageAltText}
                imagePadding={imagePadding}
              />
            )}
            <PaddingWrapper paddingBottom>
              <Heading
                data-id="offer-heading"
                heading={heading}
                colour={selectTheme.headingColour as ColourVariant}
                variant="small"
              />
            </PaddingWrapper>
          </HeaderWrapper>
          {description && (
            <PaddingWrapper paddingBottom>
              <Text colour="dark" data-id="offer-description" text={description} />
            </PaddingWrapper>
          )}
        </section>
        <FooterWrapper>
          <PaddingWrapper>
            <Link
              trackingID={`${OFFERS_PREFIX}-${kebabCase(label)}`}
              accessibleLabel={accessibleLabel}
              type="button"
              theme={selectTheme.ctaColour as Theme}
              url={url}
              variant="small"
            >
              {label}
            </Link>
          </PaddingWrapper>
          {image && imagePositionBottom && !imageBackground && (
            <OfferImage
              dataId="offer-image--bottom"
              imageSrc={imageSrc}
              imageAltText={imageAltText}
              imagePadding={imagePadding}
            />
          )}
          {terms && (
            <PaddingWrapper paddingTop>
              <Text data-id="offer-terms" variant="small" text={terms} />
            </PaddingWrapper>
          )}
        </FooterWrapper>
      </Card>
    </ThemeProvider>
  );
};
